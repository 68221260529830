$enable-antialiasing: true !default;
$enable-rfs: true !default;
$enable-rounded: true !default;
$enable-negative-margins: true !default;

$border-width: 1px !default;
$border-color: #e5e7eb !default;
$border-radius: 0.375rem !default;
$border-radius-sm: 0.5rem !default;
$border-radius-lg: 0.625rem !default;
$border-radius-pill: 9999px !default;

$min-contrast-ratio: 2 !default;
$body-color: #1f2937 !default;
$text-muted: #4b5563 !default;
$headings-color: #111827 !default;

$link-color: #4f46e5 !default;
$link-decoration: none !default;
$link-hover-decoration: underline !default;

$white: #fff !default;
$black: #000 !default;
$gray-100: #f3f4f6 !default;
$gray-200: #e5e7eb !default;
$gray-300: #d1d5db !default;
$gray-400: #9ca3af !default;
$gray-500: #6b7280 !default;
$gray-600: #4b5563 !default;
$gray-700: #374151 !default;
$gray-800: #1f2937 !default;
$gray-900: #111827 !default;

$primary: #4f46e5 !default;
$secondary: #6b7280 !default;
$success: #6ee7b7 !default;
$info: #64748b !default;
$warning: #f59e0b !default;
$danger: #ef4444 !default;
$light: #fff !default;
$dark: #111827 !default;

$primary-light: #e0e7ff !default;
$secondary-light: #9ca3af !default;
$success-light: #d1fae5 !default;
$info-light: #f8fafc !default;
$warning-light: #fef3c7 !default;
$danger-light: #fee2e2 !default;
$light-light: #e2e8f0 !default;
$dark-light: #1f2937 !default;

$primary-dark: #4338ca !default;
$secondary-dark: #4b5563 !default;
$success-dark: #10b981 !default;
$info-dark: #0f172a !default;
$warning-dark: #fcd34d !default;
$danger-dark: #fca5a5 !default;
$light-dark: #cbd5e1 !default;

$theme-colors: (
    primary: $primary,
    secondary: $secondary,
    success: $success,
    info: $info,
    warning: $warning,
    danger: $danger,
    light: $light,
    dark: $dark,
    primary-light: $primary-light,
    secondary-light: $secondary-light,
    success-light: $success-light,
    info-light: $info-light,
    warning-light: $warning-light,
    danger-light: $danger-light,
    light-light: $light-light,
    primary-dark: $primary-dark,
    secondary-dark: $secondary-dark,
    success-dark: $success-dark,
    info-dark: $info-dark,
    warning-dark: $warning-dark,
    danger-dark: $danger-dark,
    light-dark: $light-dark
) !default;

$blue: #3b82f6 !default;
$red: #ef4444 !default;
$green: #10b981 !default;

$blue-100: #dbeafe !default;
$blue-200: #bfdbfe !default;
$blue-300: #93c5fd !default;
$blue-400: #60a5fa !default;
$blue-500: #3b82f6 !default;
$blue-600: #2563eb !default;
$blue-700: #1d4ed8 !default;
$blue-800: #1e40af !default;
$blue-900: #dbeafe !default;

$indigo-100: #e0e7ff !default;
$indigo-200: #c7d2fe !default;
$indigo-300: #a5b4fc !default;
$indigo-400: #818cf8 !default;
$indigo-500: #6366f1 !default;
$indigo-600: #4f46e5 !default;
$indigo-700: #4338ca !default;
$indigo-800: #3730a3 !default;
$indigo-900: #312e81 !default;

$purple-100: #ede9fe !default;
$purple-200: #ddd6fe !default;
$purple-300: #c4b5fd !default;
$purple-400: #a78bfa !default;
$purple-500: #8b5cf6 !default;
$purple-600: #7c3aed !default;
$purple-700: #6d28d9 !default;
$purple-800: #5b21b6 !default;
$purple-900: #4c1d95 !default;

$pink-100: #fce7f3 !default;
$pink-200: #fbcfe8 !default;
$pink-300: #f9a8d4 !default;
$pink-400: #f472b6 !default;
$pink-500: #ec4899 !default;
$pink-600: #db2777 !default;
$pink-700: #be185d !default;
$pink-800: #9d174d !default;
$pink-900: #831843 !default;

$red-100: #fee2e2 !default;
$red-200: #fecaca !default;
$red-300: #fca5a5 !default;
$red-400: #f87171 !default;
$red-500: #ef4444 !default;
$red-600: #dc2626 !default;
$red-700: #b91c1c !default;
$red-800: #991b1b !default;
$red-900: #7f1d1d !default;

$green-100: #d1fae5 !default;
$green-200: #a7f3d0 !default;
$green-300: #6ee7b7 !default;
$green-400: #34d399 !default;
$green-500: #10b981 !default;
$green-600: #059669 !default;
$green-700: #047857 !default;
$green-800: #065f46 !default;
$green-900: #064e3b !default;

$font-family-base: "General Sans",sans-serif,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !default;
$font-size-base: 1rem !default;
$font-size-sm: 0.875rem !default;
$font-size-lg: 1.125rem !default;
$font-weight-bold: 700 !default;
$font-weight-medium: 500 !default;
$small-font-size: .875em !default;

$headings-font-weight: 700 !default;
$headings-font-family: "General Sans",sans-serif,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !default;
$headings-line-height: 1.1 !default;

$h1-font-size: 5rem !default;
$h2-font-size: 3.75rem !default;
$h3-font-size: 3rem !default;
$h4-font-size: 2.25rem !default;
$h5-font-size: 1.125rem !default;
$h6-font-size: 1rem !default;

$display-font-family: "General Sans",sans-serif,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !default;
$display-font-weight: 400 !default;
$display-font-sizes: (
    1: 3.25rem,
    2: 3rem,
    3: 2.625rem,
    4: 2.25rem,
    5: 2rem
) !default;
$font-sizes: (
    1: 5rem,
    2: 3.75rem,
    3: 3rem,
    4: 2.25rem,
    5: 1.75rem,
    6: 1.313rem,
    7: 1.125rem,
    8: 1rem,
    9: 0.875rem,
    10: 0.688rem
) !default;

$display-line-height: 1 !default;

$lead-font-size: 1rem !default;
$lead-font-weight: 500 !default;

$box-shadow: 0px 14px 34px rgba(79, 70, 229, 0.2), 0px 4px 25px rgba(48, 41, 91, 0.15) !default;
$box-shadow-sm: 5px 5px 25px rgba(0, 0, 0, 0.05) !default;
$box-shadow-lg: 0px 34px 84px rgba(0, 0, 0, 0.1) !default;
$box-shadow-xl: 0px 53px 84px rgba(0, 0, 0, 0.2) !default;
$box-shadow-inset: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !default;

$spacers: (
    0: 0px,
    1: 0.25rem,
    2: 0.5rem,
    3: 0.75rem,
    4: 1rem,
    5: 1.25rem,
    6: 1.5rem,
    7: 1.75rem,
    8: 2rem,
    9: 2.25rem,
    10: 2.5rem,
    11: 2.75rem,
    12: 3rem,
    14: 3.5rem,
    16: 4rem,
    20: 5rem,
    24: 6rem,
    28: 7rem,
    32: 8rem,
    36: 9rem,
    40: 10rem,
    44: 11rem,
    48: 12rem,
    52: 13rem,
    56: 14rem,
    60: 15rem,
    64: 16rem,
    72: 18rem,
    80: 20rem,
    96: 24rem
) !default;

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;

$badge-font-size: 0.688rem !default;
$badge-font-weight: 600 !default;
$badge-color: #fff !default;
$badge-padding-y: 0.375rem !default;
$badge-padding-x: 0.75rem !default;
$badge-border-radius: 9999px !default;

$input-btn-font-family: "General Sans",sans-serif,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !default;
$input-btn-font-size: 1rem !default;
$input-btn-padding-y: 1rem !default;
$input-btn-padding-x: 1.5rem !default;
$input-btn-line-height: 1.25 !default;

$btn-border-radius: 0.625rem !default;
$btn-font-weight: 600 !default;
$hover-border: transparent !default;

$input-btn-border-radius: 0.625rem !default;
$input-color: #6b7280 !default;
$input-placeholder-color: #6b7280 !default;
$input-font-size: 1rem !default;
$input-padding-y: 1rem !default;
$input-padding-x: 1rem !default;
$input-border-radius: 8px !default;
$input-border-color: #d1d5db !default;

$nav-link-font-size: 1rem !default;
$nav-link-font-weight: 500 !default;

$navbar-padding-y: 1.5rem !default;
$navbar-padding-x: 1rem !default;
$navbar-nav-link-padding-x: 1.25rem !default;
$navbar-light-color: #000 !default;
$navbar-light-hover-color: #111827 !default;
$navbar-light-active-color: #111827 !default;
$navbar-light-disabled-color: #6b7280 !default;
$navbar-dark-color: #fff !default;
$navbar-dark-hover-color: #e5e7eb !default;
$navbar-dark-active-color: #e5e7eb !default;
$navbar-dark-disabled-color: #6b7280 !default;

$alert-color: null !default;
$alert-font-size: null !default;
$alert-border-width: $border-width !default;
$alert-border-radius: $border-radius !default;

$alert-border-top-width: $alert-border-width !default;
$alert-border-right-width: $alert-border-width !default;
$alert-border-bottom-width: $alert-border-width !default;
$alert-border-left-width: $alert-border-width !default;

$alert-border-top-right-radius: $alert-border-radius !default;
$alert-border-top-left-radius: $alert-border-radius !default;
$alert-border-bottom-right-radius: $alert-border-radius !default;
$alert-border-bottom-left-radius: $alert-border-radius !default;

$badge-text-transform: null !default;

$display-font-family: null !default;

$font-weight-medium: null !default;
$font-weight-semibold: null !default;
